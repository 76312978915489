import { type Component, type Ref, computed, ref, watchEffect } from 'vue'

import type { CmsIcon } from '@backmarket/http-api/src/api-specs-content/models/icon'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { RenderDynamicIconState } from '../models/dynamic-icon-state'

/**
 * Dynamic imports have limitations in Rollup which makes it impossible to use import.meta.glob with a node_module package and alias
 * see @doc https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
 *  This object contains all icons currently used in the CMS. We can add more icons when needed
 */
const iconModules = {
  IconAddToCart: () => import('@ds/icons/IconAddToCart'),
  IconAlert: () => import('@ds/icons/IconAlert'),
  IconArchive: () => import('@ds/icons/IconArchive'),
  IconArrowDown: () => import('@ds/icons/IconArrowDown'),
  IconArrowDownLeft: () => import('@ds/icons/IconArrowDownLeft'),
  IconArrowDownRight: () => import('@ds/icons/IconArrowDownRight'),
  IconArrowLeft: () => import('@ds/icons/IconArrowLeft'),
  IconArrowRight: () => import('@ds/icons/IconArrowRight'),
  IconArrowUp: () => import('@ds/icons/IconArrowUp'),
  IconArrowUpLeft: () => import('@ds/icons/IconArrowUpLeft'),
  IconArrowUpRight: () => import('@ds/icons/IconArrowUpRight'),
  IconAttachment: () => import('@ds/icons/IconAttachment'),
  IconAvatar: () => import('@ds/icons/IconAvatar'),
  IconAvatarFilled: () => import('@ds/icons/IconAvatarFilled'),
  IconBackMarket: () => import('@ds/icons/IconBackMarket'),
  IconBackMarketApp: () => import('@ds/icons/IconBackMarketApp'),
  IconBackMarketAvatar: () => import('@ds/icons/IconBackMarketAvatar'),
  IconBadge: () => import('@ds/icons/IconBadge'),
  IconBank: () => import('@ds/icons/IconBank'),
  IconBattery: () => import('@ds/icons/IconBattery'),
  IconBellAlert: () => import('@ds/icons/IconBellAlert'),
  IconBellDisabled: () => import('@ds/icons/IconBellDisabled'),
  IconBellFilled: () => import('@ds/icons/IconBellFilled'),
  IconBellOutlined: () => import('@ds/icons/IconBellOutlined'),
  IconBill: () => import('@ds/icons/IconBill'),
  IconBluetooth: () => import('@ds/icons/IconBluetooth'),
  IconBurger: () => import('@ds/icons/IconBurger'),
  IconCalendar: () => import('@ds/icons/IconCalendar'),
  IconCalendarWithCheck: () => import('@ds/icons/IconCalendarWithCheck'),
  IconCamera: () => import('@ds/icons/IconCamera'),
  IconCardView: () => import('@ds/icons/IconCardView'),
  IconCellSignal: () => import('@ds/icons/IconCellSignal'),
  IconChargingWire: () => import('@ds/icons/IconChargingWire'),
  IconChart: () => import('@ds/icons/IconChart'),
  IconCharter: () => import('@ds/icons/IconCharter'),
  IconCheckInCircle: () => import('@ds/icons/IconCheckInCircle'),
  IconCheckInCircleFilled: () => import('@ds/icons/IconCheckInCircleFilled'),
  IconCheckLarge: () => import('@ds/icons/IconCheckLarge'),
  IconCheckSmall: () => import('@ds/icons/IconCheckSmall'),
  IconChevronDown: () => import('@ds/icons/IconChevronDown'),
  IconChevronLeft: () => import('@ds/icons/IconChevronLeft'),
  IconChevronRight: () => import('@ds/icons/IconChevronRight'),
  IconChevronUp: () => import('@ds/icons/IconChevronUp'),
  IconClock: () => import('@ds/icons/IconClock'),
  IconComment: () => import('@ds/icons/IconComment'),
  IconCompass: () => import('@ds/icons/IconCompass'),
  IconComputerMouse: () => import('@ds/icons/IconComputerMouse'),
  IconContact: () => import('@ds/icons/IconContact'),
  IconController: () => import('@ds/icons/IconController'),
  IconCookie: () => import('@ds/icons/IconCookie'),
  IconCopy: () => import('@ds/icons/IconCopy'),
  IconCpu: () => import('@ds/icons/IconCpu'),
  IconCreditCard: () => import('@ds/icons/IconCreditCard'),
  IconCross: () => import('@ds/icons/IconCross'),
  IconCrossInCircle: () => import('@ds/icons/IconCrossInCircle'),
  IconCrossInCircleFilled: () => import('@ds/icons/IconCrossInCircleFilled'),
  IconCrossSmall: () => import('@ds/icons/IconCrossSmall'),
  IconCrosshair: () => import('@ds/icons/IconCrosshair'),
  IconCupcake: () => import('@ds/icons/IconCupcake'),
  IconCurrencyAustralianDollar: () =>
    import('@ds/icons/IconCurrencyAustralianDollar'),
  IconCurrencyDollar: () => import('@ds/icons/IconCurrencyDollar'),
  IconCurrencyEuro: () => import('@ds/icons/IconCurrencyEuro'),
  IconCurrencyPound: () => import('@ds/icons/IconCurrencyPound'),
  IconCurrencySwedishKrone: () => import('@ds/icons/IconCurrencySwedishKrone'),
  IconCurrencyYen: () => import('@ds/icons/IconCurrencyYen'),
  IconCycle: () => import('@ds/icons/IconCycle'),
  IconDesign: () => import('@ds/icons/IconDesign'),
  IconDiamond: () => import('@ds/icons/IconDiamond'),
  IconDoubleChevronUp: () => import('@ds/icons/IconDoubleChevronUp'),
  IconDownload: () => import('@ds/icons/IconDownload'),
  IconEdit: () => import('@ds/icons/IconEdit'),
  IconEiffelTower: () => import('@ds/icons/IconEiffelTower'),
  IconEqual: () => import('@ds/icons/IconEqual'),
  IconEqualizer: () => import('@ds/icons/IconEqualizer'),
  IconEqualizerSmall: () => import('@ds/icons/IconEqualizerSmall'),
  IconExpand: () => import('@ds/icons/IconExpand'),
  IconExport: () => import('@ds/icons/IconExport'),
  IconExternalLink: () => import('@ds/icons/IconExternalLink'),
  IconEyeClosed: () => import('@ds/icons/IconEyeClosed'),
  IconEyeOpen: () => import('@ds/icons/IconEyeOpen'),
  IconEyes: () => import('@ds/icons/IconEyes'),
  IconFactory: () => import('@ds/icons/IconFactory'),
  IconFigma: () => import('@ds/icons/IconFigma'),
  IconFile: () => import('@ds/icons/IconFile'),
  IconFingerprint: () => import('@ds/icons/IconFingerprint'),
  IconFire: () => import('@ds/icons/IconFire'),
  IconFlag: () => import('@ds/icons/IconFlag'),
  IconFloat: () => import('@ds/icons/IconFloat'),
  IconGear: () => import('@ds/icons/IconGear'),
  IconGearUpdate: () => import('@ds/icons/IconGearUpdate'),
  IconGift: () => import('@ds/icons/IconGift'),
  IconGitHubFilled: () => import('@ds/icons/IconGitHubFilled'),
  IconGlasses: () => import('@ds/icons/IconGlasses'),
  IconGlobe: () => import('@ds/icons/IconGlobe'),
  IconGoldenGate: () => import('@ds/icons/IconGoldenGate'),
  IconGpu: () => import('@ds/icons/IconGpu'),
  IconGrowth: () => import('@ds/icons/IconGrowth'),
  IconHdmi: () => import('@ds/icons/IconHdmi'),
  IconHeadset: () => import('@ds/icons/IconHeadset'),
  IconHeart: () => import('@ds/icons/IconHeart'),
  IconHeartBroken: () => import('@ds/icons/IconHeartBroken'),
  IconHeartDiagnostic: () => import('@ds/icons/IconHeartDiagnostic'),
  IconHeartExploded: () => import('@ds/icons/IconHeartExploded'),
  IconHeartFilled: () => import('@ds/icons/IconHeartFilled'),
  IconHome: () => import('@ds/icons/IconHome'),
  IconHomeFilled: () => import('@ds/icons/IconHomeFilled'),
  IconImage: () => import('@ds/icons/IconImage'),
  IconInfo: () => import('@ds/icons/IconInfo'),
  IconInfoFilled: () => import('@ds/icons/IconInfoFilled'),
  IconKeyboard: () => import('@ds/icons/IconKeyboard'),
  IconLanguage: () => import('@ds/icons/IconLanguage'),
  IconLaptopCamera: () => import('@ds/icons/IconLaptopCamera'),
  IconLeaf: () => import('@ds/icons/IconLeaf'),
  IconLightbulb: () => import('@ds/icons/IconLightbulb'),
  IconListView1: () => import('@ds/icons/IconListView1'),
  IconListView2: () => import('@ds/icons/IconListView2'),
  IconLocationPinFilled: () => import('@ds/icons/IconLocationPinFilled'),
  IconLocationPinOutlined: () => import('@ds/icons/IconLocationPinOutlined'),
  IconLockLocked: () => import('@ds/icons/IconLockLocked'),
  IconLockUnlocked: () => import('@ds/icons/IconLockUnlocked'),
  IconLogOut: () => import('@ds/icons/IconLogOut'),
  IconMagsafe: () => import('@ds/icons/IconMagsafe'),
  IconMail: () => import('@ds/icons/IconMail'),
  IconMedal: () => import('@ds/icons/IconMedal'),
  IconMegaphone: () => import('@ds/icons/IconMegaphone'),
  IconMinus: () => import('@ds/icons/IconMinus'),
  IconMinusInCircle: () => import('@ds/icons/IconMinusInCircle'),
  IconMoney: () => import('@ds/icons/IconMoney'),
  IconMoreVertical: () => import('@ds/icons/IconMoreVertical'),
  IconMove: () => import('@ds/icons/IconMove'),
  IconOfficial: () => import('@ds/icons/IconOfficial'),
  IconOfficialFilled: () => import('@ds/icons/IconOfficialFilled'),
  IconPackage: () => import('@ds/icons/IconPackage'),
  IconPaintBrush: () => import('@ds/icons/IconPaintBrush'),
  IconPeople: () => import('@ds/icons/IconPeople'),
  IconPerformance: () => import('@ds/icons/IconPerformance'),
  IconPlug: () => import('@ds/icons/IconPlug'),
  IconPlus: () => import('@ds/icons/IconPlus'),
  IconPlusInCircle: () => import('@ds/icons/IconPlusInCircle'),
  IconPower: () => import('@ds/icons/IconPower'),
  IconPowerAdapterApple: () => import('@ds/icons/IconPowerAdapterApple'),
  IconPriceTag: () => import('@ds/icons/IconPriceTag'),
  IconPriceUpdate: () => import('@ds/icons/IconPriceUpdate'),
  IconPrint: () => import('@ds/icons/IconPrint'),
  IconPromo: () => import('@ds/icons/IconPromo'),
  IconQuality: () => import('@ds/icons/IconQuality'),
  IconReassuranceParcelOutlined: () =>
    import('@ds/icons/IconReassuranceParcelOutlined'),
  IconRecommendation: () => import('@ds/icons/IconRecommendation'),
  IconRefresh: () => import('@ds/icons/IconRefresh'),
  IconRefund: () => import('@ds/icons/IconRefund'),
  IconRepairHome: () => import('@ds/icons/IconRepairHome'),
  IconRepairMailIn: () => import('@ds/icons/IconRepairMailIn'),
  IconRepairSelf: () => import('@ds/icons/IconRepairSelf'),
  IconRepairStore: () => import('@ds/icons/IconRepairStore'),
  IconRocket: () => import('@ds/icons/IconRocket'),
  IconScratches: () => import('@ds/icons/IconScratches'),
  IconSdhcCard: () => import('@ds/icons/IconSdhcCard'),
  IconSearch: () => import('@ds/icons/IconSearch'),
  IconSearchFilled: () => import('@ds/icons/IconSearchFilled'),
  IconSend: () => import('@ds/icons/IconSend'),
  IconShare: () => import('@ds/icons/IconShare'),
  IconShareAndroid: () => import('@ds/icons/IconShareAndroid'),
  IconShop: () => import('@ds/icons/IconShop'),
  IconShoppingBag: () => import('@ds/icons/IconShoppingBag'),
  IconSim: () => import('@ds/icons/IconSim'),
  IconSiren: () => import('@ds/icons/IconSiren'),
  IconSmartphone: () => import('@ds/icons/IconSmartphone'),
  IconSmartphoneBroken: () => import('@ds/icons/IconSmartphoneBroken'),
  IconSmartphoneCamera: () => import('@ds/icons/IconSmartphoneCamera'),
  IconSmartphoneScreenSize: () => import('@ds/icons/IconSmartphoneScreenSize'),
  IconSort: () => import('@ds/icons/IconSort'),
  IconSos: () => import('@ds/icons/IconSos'),
  IconSoundLow: () => import('@ds/icons/IconSoundLow'),
  IconSoundOff: () => import('@ds/icons/IconSoundOff'),
  IconSoundOn: () => import('@ds/icons/IconSoundOn'),
  IconSparkles: () => import('@ds/icons/IconSparkles'),
  IconSpinner: () => import('@ds/icons/IconSpinner'),
  IconStarFilled: () => import('@ds/icons/IconStarFilled'),
  IconStarHalf: () => import('@ds/icons/IconStarHalf'),
  IconStarOutlined: () => import('@ds/icons/IconStarOutlined'),
  IconSunBeam: () => import('@ds/icons/IconSunBeam'),
  IconSunFace: () => import('@ds/icons/IconSunFace'),
  IconSwap: () => import('@ds/icons/IconSwap'),
  IconSwapFilled: () => import('@ds/icons/IconSwapFilled'),
  IconThumbsDownFilled: () => import('@ds/icons/IconThumbsDownFilled'),
  IconThumbsDownOutlined: () => import('@ds/icons/IconThumbsDownOutlined'),
  IconThumbsUpFilled: () => import('@ds/icons/IconThumbsUpFilled'),
  IconThumbsUpOutlined: () => import('@ds/icons/IconThumbsUpOutlined'),
  IconTools: () => import('@ds/icons/IconTools'),
  IconTouch: () => import('@ds/icons/IconTouch'),
  IconTouchbar: () => import('@ds/icons/IconTouchbar'),
  IconTrade: () => import('@ds/icons/IconTrade'),
  IconTrash: () => import('@ds/icons/IconTrash'),
  IconTrophy: () => import('@ds/icons/IconTrophy'),
  IconTruck: () => import('@ds/icons/IconTruck'),
  IconTruckExpress: () => import('@ds/icons/IconTruckExpress'),
  IconTv: () => import('@ds/icons/IconTv'),
  IconUndo: () => import('@ds/icons/IconUndo'),
  IconUpload: () => import('@ds/icons/IconUpload'),
  IconWarning: () => import('@ds/icons/IconWarning'),
  IconWatch: () => import('@ds/icons/IconWatch'),
  IconWaterdrop: () => import('@ds/icons/IconWaterdrop'),
  IconWifi: () => import('@ds/icons/IconWifi'),
} as const satisfies Record<CmsIcon['icon'], () => Component>

function keyExists(icon: string): icon is keyof typeof iconModules {
  return Object.keys(iconModules).includes(icon)
}

async function importIconComponent(icon: string): Promise<Component> {
  if (!keyExists(icon)) {
    throw new Error(`[CMS Icon] Could not find path to import icon ${icon}`)
  }

  const importIconFunction = iconModules[icon]
  const iconModule = (await importIconFunction()) as {
    [icon: string]: Component
  }
  const iconComponent = iconModule[icon]

  return iconComponent
}

export function useImportIconComponent(iconName: Ref<string>) {
  const logger = useLogger()

  // NB: we avoid using a ref since we do not need this to be reactive and it negatively impacts performance
  // This is also prevents the related warning: "Vue received a Component that was made a reactive object"
  // See issue discussed here https://github.com/vuejs/core/issues/11921
  // NB2: if we need this to be reactive, we could use shallowRef
  const icon: { component: Component | null } = { component: null }

  const renderIconState = ref<RenderDynamicIconState>(
    RenderDynamicIconState.Pending,
  )

  const success = computed(
    () => renderIconState.value === RenderDynamicIconState.Success,
  )

  const pending = computed(
    () => renderIconState.value === RenderDynamicIconState.Pending,
  )

  watchEffect(async () => {
    try {
      icon.component = await importIconComponent(iconName.value)
      renderIconState.value = RenderDynamicIconState.Success
    } catch (err) {
      logger.error(
        `[CMS Icon] icon ${
          iconName.value
        } does not exist in the Design System. Error ${
          (err as Error)?.message
        }`,
        { owners: ['bot-squad-content-front'] },
      )
      renderIconState.value = RenderDynamicIconState.Error
    }
  })

  return { icon, success, pending }
}
