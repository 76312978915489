<script lang="ts">
import { type PropType, defineComponent, h, useSlots } from 'vue'

import { useI18n } from '../composables/useI18n'
import type { I18nDefinition } from '../types'

import { replacePlaceholderWithSlots } from './FormattedMessage.utils'

export default defineComponent({
  props: {
    definition: {
      type: Object as PropType<I18nDefinition>,
      required: true,
    },

    tag: {
      type: String,
      default: 'span',
    },

    values: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const i18n = useI18n()
    const slots = useSlots()

    return () => {
      const executedSlots = Object.entries(slots).reduce(
        (acc, [key, slot]) =>
          typeof slot === 'function' ? { ...acc, [key]: slot() } : acc,
        {},
      )

      const message = i18n(props.definition, props.values)
      if (!message) {
        return null
      }

      const content =
        // If both values are equals, that means the raw formatter returned the key
        // and did not format it at all. We just serve this value, and do not try to
        // process it.
        message === props.definition.id
          ? message
          : replacePlaceholderWithSlots(message, executedSlots || {})

      return h(props.tag, content)
    }
  },
})
</script>
