<template>
  <div>
    <BlockTitle :subtitle :title />
    <ul
      class="rounded-lg grid gap-12 p-12"
      :class="reassuranceClasses.containerClass"
    >
      <li
        v-for="(block, index) of mappedBlocks"
        :key="block.id"
        class="flex gap-12"
      >
        <div
          class="rounded-md h-40 w-40 p-8"
          :class="reassuranceClasses.iconContainerClass"
        >
          <DynamicIcon
            :class="reassuranceClasses.iconClass"
            :icon="block.iconAppearance?.icon || block.icon"
            size="medium"
          />
        </div>
        <div class="text-static-default-hi flex flex-col justify-center">
          <RevLink
            v-if="
              (block.link || block.onClick) && block.linkPosition === 'title'
            "
            class="body-2-link"
            :class="block.titleClass"
            :to="block.link?.href"
            @click="handleClick({ block, linkPosition: 'title', index })"
          >
            {{ block.title }}
          </RevLink>

          <div v-else class="body-2-bold" :class="block.titleClass">
            {{ block.title }}
          </div>

          <RevLink
            v-if="
              (block.link || block.onClick) &&
              block.linkPosition === 'description'
            "
            class="caption-link"
            :class="block.descriptionClass"
            :to="block.link?.href"
            @click="handleClick({ block, linkPosition: 'description', index })"
          >
            {{ block.description }}
          </RevLink>

          <div
            v-else-if="!!block.description"
            class="caption"
            :class="block.descriptionClass"
          >
            {{ block.description }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type {
  ReassuranceItem as ApiReassuranceItem,
  ReassuranceProps as ApiReassuranceProps,
} from '@backmarket/http-api/src/api-specs-content/models/reinsurance'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import type { ContentBlockProps } from '../../models/content-block'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'
import DynamicIcon from '../../shared-components/DynamicIcon/DynamicIcon.vue'

import { getClasses, getTextClampingClasses } from './Reassurance.utils'

type ReassuranceItem = ApiReassuranceItem & {
  onClick?: () => void
}

type ReassuranceProps = Omit<ApiReassuranceProps, 'blocks'> & {
  blocks: ReassuranceItem[]
}

const props = withDefaults(
  defineProps<ReassuranceProps & ContentBlockProps>(),
  {
    mobileScreenCols: 1,
    hasBackground: false,
    theme: 'gray',
  },
)

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const mappedBlocks = computed(() => {
  return props.blocks.map((block) => {
    const { descriptionClass, titleClass } = getTextClampingClasses({
      description: block.description,
      mobileScreenCols: props.mobileScreenCols,
    })

    return {
      ...block,
      titleClass,
      descriptionClass,
    }
  })
})

const reassuranceClasses = computed(() =>
  getClasses({
    theme: props.theme,
    hasBackground: props.hasBackground,
    itemsCount: props.blocks.length,
    mobileScreenCols: props.mobileScreenCols,
  }),
)

const { trackClick } = useTracking()

function handleClick({
  block,
  linkPosition,
  index,
}: {
  block: ReassuranceProps['blocks'][number]
  linkPosition: 'title' | 'description'
  index: number
}) {
  if (block.onClick) block.onClick()
  trackClick({
    ...(props.tracking || {}),
    name: (linkPosition === 'title' ? block.title : block.description) || '',
    position: index + 1,
    linkPosition,
  })
}
</script>
