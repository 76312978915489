<template>
  <div class="mx-8 my-0 md:mx-20">
    <div class="text-static-default-hi mb-8 flex">
      <div
        v-for="(hourDigit, index) in digits.formatted"
        :key="hourDigit"
        class="bg-surface-default-mid rounded-xs heading-1 mr-4 flex h-[2.875rem] w-[2.25rem] items-center justify-center px-8 py-4 last:mr-0"
      >
        <div class="h-36" :data-test="`digit-${index}`">{{ hourDigit }}</div>
      </div>
    </div>
    <span class="body-2 uppercase">
      {{ i18n(translations[unit], { duration: digits.raw }) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './Countdown.translations'
import type { CountdownDigits, TimeUnit } from './helpers/models'

defineProps<{ digits: CountdownDigits; unit: TimeUnit }>()

const i18n = useI18n()
</script>
