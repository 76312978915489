import type { VNode } from 'vue'

import type { I18nSlots } from '../types'

/**
 * This function transforms a given message into an array of strings and/or vnodes.
 *
 * @example
 * replacePlaceholderWithSlots('Hello {world}', …) // ['Hello, ', VNode]
 *
 * @example
 * replacePlaceholderWithSlots ('My name is {first} {last}.', …) // ['My name is ', VNode, ' ', VNode, '.']
 *
 * 1. To take advantage of HTML (and components) interpolation, we need to return
 *    an array, and let the Vue compiler do its work. We can't just compute a
 *    string to display in our templates.
 *
 * 2. Note that by doing so, we gain everything Vue has to offer. We can use CSS,
 *    JS and whatever in our translations. We can even use Vue components as-is.
 *
 * 3. We also prevent a huge security breach: XSS issues. Because Vue does the work
 *    for us, we no longer need to manually use `v-html`. So, an attacker would
 *    not be able to inject arbitrary tags (and attributes) in our pages.
 *
 */
export function replacePlaceholderWithSlots<Message extends string>(
  message: Message,
  slots: I18nSlots,
): (VNode | string)[] {
  const tokens: (VNode | string)[] = []

  let text = ''
  let position = 0

  while (position < message.length) {
    const character = message[position]

    switch (character) {
      case '{':
        tokens.push(text)
        text = ''
        break

      case '}':
        tokens.push(slots[text as keyof typeof slots])
        text = ''
        break

      default:
        text += character
        break
    }

    position += 1
  }

  // Do not forget to push the rest of the message when we reach the end.
  tokens.push(text)

  // Since we do not validate the items pushed into the `tokens` array, we might
  // end up with falsy values (undefined, empty strings…). To avoid polluting
  // our Vue templates with those, let's remove them at the end.
  return tokens.filter(Boolean)
}
